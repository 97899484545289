import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { childrenPropsSDKFactory } from '../../../core/corvid/props-factories/childrenPropsSDKFactory';
import {
  IFooterContainerProps,
  IFooterContainerSDK,
  IFooterContainerSDKFactory,
} from '../FooterContainer.types';

export const sdk: IFooterContainerSDKFactory = composeSDKFactories<
  IFooterContainerProps,
  IFooterContainerSDK
>(childrenPropsSDKFactory);
