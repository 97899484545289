import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  IStylableButtonProps,
  IStylableButtonSDK,
  IStylableButtonOwnSDKFactory,
} from '../StylableButton.types';
import {
  clickPropsSDKFactory,
  labelPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  linkPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const stylableButtonSDKFactory: IStylableButtonOwnSDKFactory = () => {
  return {};
};

export const sdk = composeSDKFactories<
  IStylableButtonProps,
  IStylableButtonSDK
>(
  stylableButtonSDKFactory,
  clickPropsSDKFactory,
  labelPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  linkPropsSDKFactory,
);
