import {
  withValidation,
  composeSDKFactories,
  assert,
} from '@wix/editor-elements-corvid-utils';
import { hiddenPropsSDKFactory } from '../../../core/corvid/props-factories';
import {
  IWRichTextSDKFactory,
  IWRichTextProps,
  IWRichTextSDK,
} from '../WRichText.types';
import {
  wixGuard,
  endBlockTagRegex,
  endTagRegex,
  startTagRegex,
} from '../constants';

import {
  applyTransformationForGetHtml,
  applyTransformationForSetHtml,
  convertLinkProperties,
  removeWixGuard,
  decode,
  insertTextInHtml,
  escape,
  unescape,
} from './utils';

export type WRichTextSDK = {
  text: string;
  html: string;
};

const endBlockTagPattern = new RegExp(endBlockTagRegex, 'mg');
const endTagPattern = new RegExp(endTagRegex, 'mg');
const startTagPattern = new RegExp(startTagRegex, 'mg');

export const _wRichTextSdkFactory: IWRichTextSDKFactory = ({
  setProps,
  props,
  linkUtils,
}) => ({
  get html() {
    return removeWixGuard(applyTransformationForGetHtml(props.html));
  },

  set html(value) {
    const flow = (...fns: Array<Function>) => (html: string) =>
      fns.reduce((output, fn) => fn(output), html);

    const isUndefined = (str: string) => (assert.isNil(str) ? '' : str);

    const convertLink = (str: string) =>
      convertLinkProperties(str, linkUtils.getLinkProps);

    setProps({
      html: flow(
        isUndefined,
        applyTransformationForSetHtml,
        convertLink,
      )(value),
    });
  },

  get text() {
    if (props.html === undefined) {
      return '';
    }

    return decode(
      unescape(
        removeWixGuard(props.html)
          .replace(/\n/g, '')
          .replace(/<br>/g, '\n')
          .replace(/<br><\/br>/g, '\n')
          .replace(/<br\s*\/?>/g, '\n')
          .replace(endBlockTagPattern, '\n')
          .replace(endTagPattern, '')
          .replace(startTagPattern, '')
          .trim(),
      ),
    );
  },

  set text(value) {
    const escapedHTML = value ? escape(value).replace(/\n/g, '<br>') : wixGuard;

    setProps({ html: insertTextInHtml(props.html, escapedHTML) });
  },
});

const wRichTextSDKFactory = withValidation(_wRichTextSdkFactory, {
  type: ['object'],
  properties: {
    html: { type: ['string', 'nil'], warnIfNil: true },
    text: { type: ['string', 'nil'], warnIfNil: true },
  },
});

export const sdk = composeSDKFactories<IWRichTextProps, any, IWRichTextSDK>(
  wRichTextSDKFactory,
  hiddenPropsSDKFactory,
);
