import {
  withValidation,
  assert,
  reportError,
} from '@wix/editor-elements-corvid-utils';
import { IVideoPlayerSDKFactory } from '../VideoPlayer.types';
import { createMediaItemUri } from '../../../core/corvid/media/mediaItemUtils';
import { isValidMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';
import {
  getFullMediaData,
  getMediaDataFromSrc,
} from '../../../core/corvid/media/backgroundUtils';

const VideoPlayerSDKFactory: IVideoPlayerSDKFactory = ({
  setProps,
  props,
  compRef,
}) =>
  ({
    get title() {
      return props.playableConfig.title || '';
    },

    set title(title: string) {
      setProps({
        playableConfig: {
          ...props.playableConfig,
          title,
        },
      });
    },

    get isMuted() {
      return props.isMuted;
    },

    get isPlaying() {
      return props.isPlaying;
    },

    get description() {
      return props.playableConfig.description || '';
    },

    set description(description: string) {
      setProps({
        playableConfig: {
          ...props.playableConfig,
          description,
        },
      });
    },

    get currentTime() {
      return props.currentTime || 0;
    },

    get duration() {
      return props.duration || 0;
    },

    get volume() {
      return props.volume || 0;
    },

    set volume(volume: number) {
      compRef.setVolume(volume);
    },

    get src() {
      if (Array.isArray(props.src)) {
        return props.src[0];
      }
      return props.src;
    },

    set src(value: string) {
      const src = assert.isNil(value) ? '' : value;

      if (isValidMediaSrc(src, 'video')) {
        const mediaData = getMediaDataFromSrc(value);
        if (!mediaData) {
          return;
        }

        if (mediaData.type === 'WixVideo') {
          getFullMediaData(mediaData, fullMediaRefData => {
            if (!fullMediaRefData) {
              return;
            }

            setProps({
              playableConfig: {
                ...props.playableConfig,
                poster: {
                  ...props.playableConfig.poster,
                  ...fullMediaRefData.mediaObject.posterImageRef,
                },
              },
              src: `https://video.wixstatic.com/${fullMediaRefData.mediaObject.qualities[0].url}`, // TODO staticVideoUrl
            });
          });
        }
      } else if (isValidMediaSrc(src, 'image')) {
        // This checks whether url is an external link (http/https)
        // TODO: create proper matchers to make this readable
        setProps({ src: value });
      } else {
        reportError(
          `The "src" property cannot be set to "${value}". It must be a valid URL starting with "http://", "https://", or a valid video URL starting with "wix:video://".`,
        );
      }
    },

    get poster() {
      const { width, height, uri } = props.playableConfig!.poster!;
      const { title } = props.playableConfig;

      const mediaItemUri = createMediaItemUri({
        mediaId: uri,
        width,
        height,
        title,
        type: 'image',
      });

      return mediaItemUri.item || '';
    },

    set poster(uri: string) {
      setProps({
        playableConfig: {
          ...props.playableConfig,
          poster: { ...props.playableConfig!.poster!, uri },
        },
      });
    },

    play() {
      return compRef.play();
    },

    pause() {
      return compRef.pause();
    },

    stop() {
      return compRef.stop();
    },

    togglePlay() {
      return compRef.togglePlay();
    },

    mute() {
      return compRef.mute();
    },

    unmute() {
      return compRef.unmute();
    },

    seek(timePoint: number) {
      return compRef.seek(timePoint);
    },
  } as any);

export const sdk: IVideoPlayerSDKFactory = withValidation(
  VideoPlayerSDKFactory,
  {
    type: ['object'],
    properties: {
      title: { type: ['string', 'nil'], warnIfNil: true },
      description: { type: ['string', 'nil'], warnIfNil: true },
      volume: {
        type: ['number', 'nil'],
        minimum: 0,
        maximum: 100,
        warnIfNil: true,
      },
      poster: { type: ['string', 'nil'], warnIfNil: true },
      src: {
        type: ['string', 'nil'],
        warnIfNil: true,
      },
    },
  },
);
