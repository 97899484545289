import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { PageProps, IPageSDK, IPageSDKFactory } from '../Page.types';
import {
  clickPropsSDKFactory,
  childrenPropsSDKFactory,
} from '../../../core/corvid/props-factories';

export const pageSDKFactory: IPageSDKFactory = ({ sdkData }) => {
  return {
    get description() {
      return sdkData.currentPageSEODescription;
    },

    get keywords() {
      return sdkData.currentPageSEOKeywords;
    },

    get title() {
      return sdkData.currentUrlPageTitle;
    },

    get visibleInMenu() {
      return sdkData.visibleInMenu;
    },
  };
};

export const sdk = composeSDKFactories<PageProps, IPageSDK>(
  pageSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
);
