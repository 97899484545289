import {
  withValidation,
  composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';

import {
  IHtmlComponentSDKFactory,
  IHtmlComponentProps,
  IHtmlComponentSDK,
} from '../HtmlComponent.types';

export const _htmlComponentSdkFactory: IHtmlComponentSDKFactory = ({
  setProps,
  props,
}) => {
  return {
    get id() {
      return props.id;
    },

    get scrolling() {
      return props.scrolling;
    },
    set scrolling(value) {
      setProps({ scrolling: value });
    },

    get src() {
      return props.url;
    },
    set src(value) {
      setProps({ url: value });
    },

    allowFullScreen: () => setProps({ allow: 'fullscreen' }),
  };
};

const htmlComponentSdkFactory = withValidation(_htmlComponentSdkFactory, {
  type: ['object'],
  properties: {
    id: { type: ['string'] },
    src: { type: ['string'] },
    scrolling: { type: ['string'], enum: ['yes', 'no', 'auto'] },
  },
});

export const sdk = composeSDKFactories<IHtmlComponentProps, IHtmlComponentSDK>(
  htmlComponentSdkFactory,
);
