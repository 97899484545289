import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  IMediaContainerSDK,
  IMediaContainerSDKFactory,
  MediaContainerCompProps,
} from '../MediaContainer.types';
import { backgroundPropsSDKFactory } from '../../../core/corvid/props-factories/backgroundPropsSDKFactory';

export const sdk: IMediaContainerSDKFactory = composeSDKFactories<
  MediaContainerCompProps,
  IMediaContainerSDK
>(backgroundPropsSDKFactory);
