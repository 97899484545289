import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { ImageButtonProps, ImageButtonSDK } from '../ImageButton.types';
import {
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  labelPropsSDKFactory,
  clickPropsSDKFactory,
  linkPropsSDKFactory,
} from '../../../core/corvid/props-factories';

export const sdk = composeSDKFactories<ImageButtonProps, ImageButtonSDK>(
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  labelPropsSDKFactory,
  clickPropsSDKFactory,
  linkPropsSDKFactory,
);
