const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !Number.isNaN(value);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isBoolean(value: unknown): value is boolean {
  return value === true || value === false;
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date && !Number.isNaN(value.getTime());
}

export function isFunction(value: unknown): value is Function {
  return typeof value === 'function';
}

export function isArray<TItem = any>(value: unknown): value is Array<TItem> {
  return Array.isArray(value);
}

export function isObject(value: unknown): value is object {
  return typeof value === 'object' && value !== null && !isArray(value);
}

export function isInteger(value: unknown): value is number {
  return Number.isInteger(value as any);
}

export function isNil(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}

export function isIn(value: any, arr: Array<any>): boolean {
  return arr.includes(value);
}

export function isAbove(value: number, limit: number) {
  return value > limit;
}

export function isBelow(value: number, limit: number) {
  return value < limit;
}

export function isEmail(value: string) {
  return emailRegExp.test(value);
}

export function is(value: any, predicates: Array<(value: any) => boolean>) {
  return predicates.every(p => p(value));
}
