import {
  ValidateFunction,
  CustomValidator,
  ValidationData,
  ValidityKey,
  ValidityKeyWithMessage,
  ValueValidator,
} from './inputValidationTypes';

const INVALID_MESSAGES: { [key in ValidityKeyWithMessage]: string } = {
  valueMissing: 'value missing',
  patternMismatch: 'pattern mismatch',
  rangeOverflow: 'range overflow',
  rangeUnderflow: 'range underflow',
  stepMismatch: 'step mismatch',
  typeMismatch: 'type mismatch',
  fileNotUploaded: 'file not uploaded',
};

export const INITIAL_VALIDATION_DATA: ValidationData = {
  validity: {
    badInput: false,
    customError: false,
    fileNotUploaded: false,
    patternMismatch: false,
    rangeOverflow: false,
    rangeUnderflow: false,
    stepMismatch: false,
    tooLong: false,
    tooShort: false,
    typeMismatch: false,
    valid: true,
    valueMissing: false,
  },
  validationMessage: '',
};

export const getValidationMessage = <T extends ValidityKeyWithMessage>(
  validityKey: T,
): typeof INVALID_MESSAGES[T] => INVALID_MESSAGES[validityKey];

export const getCustomValidityMessage = (
  validationData: ValidationData,
): string => {
  const hasCustomError = !!validationData.validity.customError;

  return hasCustomError ? validationData.validationMessage : '';
};

export const addErrorToValidationData = (
  validationData: ValidationData,
  validityKey: ValidityKey,
  validationMessage: string,
) => ({
  validity: {
    ...validationData.validity,
    [validityKey]: true,
    valid: false,
  },
  validationMessage,
});

export const addCustomValidityToValidationData = (
  validationData: ValidationData,
  customValidityMessage: string,
) =>
  addErrorToValidationData(
    validationData,
    'customError',
    customValidityMessage || '',
  );

export const addErrorToValidationDataAndKeepMessage = (
  validationData: ValidationData,
  validityKey: ValidityKeyWithMessage,
  validationMessage?: string,
) => {
  const newValidationMessage =
    validationData.validationMessage ||
    validationMessage ||
    getValidationMessage(validityKey);

  return addErrorToValidationData(
    validationData,
    validityKey,
    newValidationMessage,
  );
};

export const checkCustomValidity = (
  customValidator: CustomValidator,
  props: any,
) => {
  let customValidityMessage = '';

  const reject = (message: string) => {
    customValidityMessage = message;
  };

  if (customValidator) {
    customValidator(props.value, reject);
  }

  return customValidityMessage;
};

export const composeValidators = <TProps>(
  validators: Array<ValueValidator<TProps>>,
): ValidateFunction<TProps> => (props: TProps) => {
  return validators.reduce(
    (validationData: ValidationData, validator: ValueValidator<TProps>) => {
      return validator(props, validationData);
    },
    INITIAL_VALIDATION_DATA,
  );
};
