import {
  composeSDKFactories,
  withValidation,
} from '@wix/editor-elements-corvid-utils';
import { childrenPropsSDKFactory } from '../../../core/corvid/props-factories/childrenPropsSDKFactory';
import {
  IHeaderContainerProps,
  IHeaderContainerSDK,
  IHeaderContainerSDKFactory,
  IHeaderContainerOwnSDKFactory,
} from '../HeaderContainer.types';

// TODO: implement actual SDK
const _headerContainerSDKFactory: IHeaderContainerOwnSDKFactory = () => {
  return {};
};

export const headerContainerSDKFactory: IHeaderContainerOwnSDKFactory = withValidation(
  _headerContainerSDKFactory,
  { type: ['object'], properties: {} },
);

export const sdk: IHeaderContainerSDKFactory = composeSDKFactories<
  IHeaderContainerProps,
  IHeaderContainerSDK
>(headerContainerSDKFactory, childrenPropsSDKFactory);
