import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import {
  ISiteButtonOwnSdk,
  ISiteButtonProps,
  ISiteButtonSDK,
} from '../SiteButton.types';
import {
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  labelPropsSDKFactory,
  linkPropsSDKFactory,
  clickPropsSDKFactoryWithUpdatePlatformHandler,
  elementPropsSDKFactory,
} from '../../../core/corvid/props-factories';

const SiteButtonSDKFactory: ISiteButtonOwnSdk = () => {
  return {};
};

export const sdk = composeSDKFactories<ISiteButtonProps, ISiteButtonSDK>(
  SiteButtonSDKFactory,
  clickPropsSDKFactoryWithUpdatePlatformHandler,
  labelPropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  linkPropsSDKFactory,
  elementPropsSDKFactory,
);
